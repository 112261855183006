/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.client-setting label {
    margin-bottom: 0.25rem;
}

.btn-site {
    border: 0;
    background-color: #2580f1;
    color: white;
    padding: 0.25rem 1rem;
}

.btn-site.disabled {
    opacity: 0.3;
}
.clientid-field {
    width: 35% !important
}

/* Chrome, Safari, Edge, Opera */
    .clientid-field .form-control::-webkit-outer-spin-button,
    .clientid-field .form-control::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
     
/* Firefox */
    .clientid-field .form-control[type=number] {
        -moz-appearance: textfield;
    }

.btn-delete {
    border: 0;
    background-color: #FF0000;
    color: white;
    padding: 0.25rem 1rem;
}

.btn-site-expand {
    border: 0;
    background-color: black;
    color: white;
    padding: 0.25rem 1rem;
}

.sub-heading {
    color: #2580f1;
}

.card {
    box-shadow: 0px 0px 5px 0px #efefef;
    padding: 1.5rem;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
    border: 0;
}

.name-tiles {
    background-color: #2580f1;
    padding: 0.25rem 1rem;
    border-radius: 0.25rem;
    margin-right: 0.5rem;
    color: white;
    margin-bottom: 0.25rem;
}

.office-active {
    background-color: #494949;
}

.multi-select .dropdown-container {
    border-radius: 0.375rem;
}

.multi-select .dropdown-heading {
    height: 36px;
}

.clients-container {
    box-shadow: 0px 0px 5px 0px #efefef;
    padding: 1.5rem;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
}

.table {
    /*box-shadow: 0px 0px 5px 0px #efef;*/
    background-color: #efef;
}

    .table tr {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
    }

    .table td {
        border-bottom: 0px;
    }

.new-layout-modal .modal-dialog {
    max-width: 70%;
}

.confirmation-delete-modal-small .modal-dialog {
    max-width: 50%;
}

.confirmation-delete-modal-large .modal-dialog {
    max-width: 70%;
}

.btn-login {
    padding: 0.25rem 3rem;
}

.h-custom {
    height: calc(100% - 73px);
}

@media (max-width: 450px) {
    .h-custom {
        height: 100%;
    }
}
.empty {
    border-color: red;
}

textarea {
    resize: none;
}

.btn-search-cross {
    top: 12px;
    cursor: pointer;
    right: 10px;
}

.cross-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    border-radius: 30px;
    /*box-shadow: 0px 0px 5px 0px #2580f1;*/
    padding: 0 3px;
    cursor: pointer;
}

.cursor-pointer{
    cursor: pointer;
}

.MuiAutocomplete-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.base-doc-pdf-viewer {
    max-height: 120px;
    overflow-y: auto;
}

.form-check-input:focus {
    box-shadow: none;
}